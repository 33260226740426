import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import adminModule from '../adminModule/index';
import campaignControlModule from '../campaignControlModule/index';
import ratingModule from '../ratingModule/index';
import keywordsModule from '../keywordsModule/index';

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    adminModule,
    campaignControlModule,
    ratingModule,
    keywordsModule
  }
};
