import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';

export const usePublisherLive = () => {
  const {
    state: { loading: publisherAuthorizeLoading },
    fetcher: authorizeFetcher
  } = useAxios();

  const {
    state: { loading: fetchingPublishers, error: fetchingPublishersError, data: publishers },
    fetcher: publishersFetcher
  } = useAxios();

  const {
    state: {
      loading: fetchingCurrentPublisher,
      error: fetchingCurrentPublisherError,
      data: currentPublisher
    },
    fetcher: currentPublisherFetcher
  } = useAxios();

  const {
    state: {
      loading: fetchingCurrentPublisherInfo,
      error: fetchingCurrentPublisherInfoError,
      data: currentPublisherInfo
    },
    fetcher: currentPublisherInfoFetcher
  } = useAxios();

  const {
    state: {
      loading: fetchingCurrentPublisherChart,
      error: fetchingCurrentPublisherChartError,
      data: currentPublisherChart
    },
    fetcher: currentPublisherChartFetcher
  } = useAxios();

  const {
    state: {
      loading: fetchingCurrentPublisherInsights,
      error: fetchingCurrentPublisherInsightsError,
      data: currentPublisherInsights
    },
    fetcher: currentPublisherInsightsFetcher
  } = useAxios();

  const publisherAuthorize = async ({ publisherId }) => {
    const apiRoute = getApiRoute('api.publisher.authorize');

    return authorizeFetcher({
      url: apiRoute,
      params: { id: publisherId }
    });
  };

  const fetchPublishers = async () => {
    const apiRoute = getApiRoute('api.publishers');

    return publishersFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  const fetchCurrentPublisher = async ({ publisherId }) => {
    const apiRoute = getApiRoute({
      name: 'api.publisher.details',
      params: { id: publisherId }
    });

    return currentPublisherFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  const fetchCurrentPublisherInfo = async ({ publisherId, dataType }) => {
    const apiRoute = getApiRoute({
      name: 'api.publisher.info',
      params: { id: publisherId },
      query: { data_type: dataType }
    });

    return currentPublisherInfoFetcher({
      url: apiRoute,
      responseAdapter: data => data
    });
  };

  const fetchCurrentPublisherChart = async ({ publisherId, dataType, timeSpan }) => {
    const apiRoute = getApiRoute({
      name: 'api.publisher.reach.chart',
      params: { id: publisherId },
      query: {
        data_type: dataType,
        timespan: timeSpan
      }
    });

    return currentPublisherChartFetcher({
      url: apiRoute,
      responseAdapter: data => data?.chart_data || {}
    });
  };

  const fetchCurrentPublisherInsights = async ({ publisherId, dataType }) => {
    const apiRoute = getApiRoute({
      name: 'api.publisher.insights',
      params: { id: publisherId },
      query: {
        data_type: dataType
      }
    });

    return currentPublisherInsightsFetcher({
      url: apiRoute,
      responseAdapter: data => data?.insightsData || {}
    });
  };

  return {
    publisherAuthorize,
    publisherAuthorizeLoading,
    fetchPublishers,
    fetchingPublishers,
    fetchingPublishersError,
    publishers,
    fetchCurrentPublisher,
    fetchingCurrentPublisher,
    fetchingCurrentPublisherError,
    currentPublisher,
    fetchCurrentPublisherInfo,
    fetchingCurrentPublisherInfo,
    fetchingCurrentPublisherInfoError,
    currentPublisherInfo,
    fetchCurrentPublisherChart,
    fetchingCurrentPublisherChart,
    fetchingCurrentPublisherChartError,
    currentPublisherChart,
    fetchCurrentPublisherInsights,
    fetchingCurrentPublisherInsights,
    fetchingCurrentPublisherInsightsError,
    currentPublisherInsights
  };
};
